/*-----------------------------------------------------------------------------------
    
    Template Name: Toro - Minimal eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Section title style
    5. Product style
    6. Delay style
    7. Subscribe style
    8. Footer style
    9. Others style
    10. Categories style
    11. Service style
    12. Instagram style
    13. Animation style
    14. Banner style
    15. Blog style
    16. Video style
    17. Brand logo style
    18. Testimonial style
    19. Breadcrumb style
    20. Shop style
    21. Sidebar style
    22. Product details style
    23. Cart style
    24. Checkout style
    25. Compare style
    26. Login register style
    27. My account style
    28. Order tracking style
    29. About us style
    30. Contact style
    31. Faq style
    32. Newsletter style
    33. Wishlist style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'slider';
@import 'section-title';
@import 'product';
@import 'delay';
@import 'subscribe';
@import 'footer';
@import 'others';
@import 'categories';
@import 'service';
@import 'instagram';
@import 'animation';
@import 'banner';
@import 'blog';
@import 'video';
@import 'brand-logo';
@import 'testimonial';
@import 'breadcrumb';
@import 'shop';
@import 'sidebar';
@import 'product-details';
@import 'cart';
@import 'checkout';
@import 'compare';
@import 'login-register';
@import 'my-account';
@import 'order-tracking';
@import 'about-us'; 
@import 'contact'; 
@import 'faq'; 
@import 'newsletter'; 
@import 'wishlist'; 



